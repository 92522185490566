import React from 'react';
import { Container, Image } from 'react-bootstrap';
import { XCircleFill } from 'react-bootstrap-icons';

import logo from '../../images/framework/logo.svg';
import Link from './link';

const NavTop = ({ children }) => {
	return (
		<Container
			className="d-flex justify-content-between align-items-center p-3 px-4 fixed-top bg-gray-100"
			fluid
		>
			<Link className="" to="/" variant="link">
				<Image className="img-fluid signin-left-sidebar-brand" src={logo} />
			</Link>
			<div>{children}</div>
			<div>
				<Link className="btn btn-link lead" to="/">
					<XCircleFill />
				</Link>
			</div>
		</Container>
	);
};

export default NavTop;
